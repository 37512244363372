import "../assets/css/style.css";
import Hero from "./Hero";
import Products from "./Products";
import Blog from "./Blog";
import About from "./About";
import Contact from "./Contact";

const Home = () => {
    return (
        <div>
            <Hero />
            <Products />
            <Blog />
            <About />
            <Contact />
        </div>
    );
};

export default Home;
