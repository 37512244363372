import React from "react";
import AboutImg from "../assets/images/about-img.jpg";
const About = () => {
    return (
        <>
            <section className="about" id="about">
                <h1 className="heading">
                    <span>about</span> us
                </h1>

                <div className="row">
                    <div className="image">
                        <img src={AboutImg} alt="" />
                    </div>

                    <div className="content">
                        <h3>what makes our food special?</h3>
                        <p>Our vision as IITian Pasta Wala Foods is to serve our customers: Enjoy only pure, healthy products (no chemicals and zero adulteration), with no maida, no palm oil and absolutely no harmful additives. Our product is made with semolina (suji), makhana (fox nut), and makka (corn).</p>
                        <p>Each variety is crafted with care, ensuring a delightful eating experience with every bite. Order Now and taste the difference with IITian Pasta Wala Foods (IPW Foods).</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
