import React from "react";
import { product } from "../Data";
import { Link } from "react-router-dom";
const Products = () => {
    return (
        <>
            <section className="products" id="products">
                <h1 className="heading">
                    our <span>Products</span>
                </h1>

                <div className="box-container">
                    {product.map((item, index) => (
                        <Link to={item.path} key={index}>
                            <div className="box" key={index}>
                                <img src={item.img} alt="" />
                            </div>
                        </Link>
                    ))}
                </div>
            </section>
        </>
    );
};

export default Products;