import productImage1 from "./assets/images/products-1.jpeg";
import productImage2 from "./assets/images/products-2.jpeg";
import productImage3 from "./assets/images/products-3.jpeg";
import productImage4 from "./assets/images/products-4.jpeg";
import productImage5 from "./assets/images/products-5.jpg";
import productImage6 from "./assets/images/products-6.jpg";
import productImage7 from "./assets/images/products-7.jpg";
import productImage8 from "./assets/images/products-8.jpg";
import productImage9 from "./assets/images/products-9.jpg";
import productImage10 from "./assets/images/products-10.jpg";
import productImage11 from "./assets/images/products-11.jpg";
import productImage12 from "./assets/images/products-12.jpg";
import blogImage1 from "./assets/images/blog-1.jpg";
import blogImage2 from "./assets/images/blog-2.jpg";
import blogImage3 from "./assets/images/blog-3.jpeg";
const product = [
    {
        img: productImage5,
        title: "Makhana Macroni",
        path: "/Products/MakhanaMacroni",
    },
    {
        img: productImage6,
        title: "Makhana Penny",
        path: "/Products/MakhanaPenny",
    },
    {
        img: productImage7,
        title: "Makhana Vermicelli",
        path: "/Products/MakhanaVermicelli",
    },
    {
        img: productImage8,
        title: "Makhana Pasta",
        path: "/Products/MakhanaPasta",
    },
    {
        img: productImage9,
        title: "Corn Macroni",
        path: "/Products/CornMacroni",
    },
    {
        img: productImage10,
        title: "Corn Penny",
        path: "/Products/CornPenny",
    },
    {
        img: productImage11,
        title: "Corn Vermicelli",
        path: "/Products/CornVermicelli",
    },
    {
        img: productImage12,
        title: "Corn Pasta",
        path: "/Products/CornPasta",
    },
    {
        img: productImage1,
        title: "Macaroni Pasta",
        path: "/Products/MacaroniPasta",
    },
    {
        img: productImage2,
        title: "Penny Pasta",
        path: "/Products/PennyPasta",
    },
    {
        img: productImage3,
        title: "Vermicelli Pasta",
        path: "/Products/VermicelliPasta",
    },
    {
        img: productImage4,
        title: "Plain Pasta",
        path: "/Products/PlainPasta",
    },
];
const blog = [
    {
        img: blogImage1,
        path: "/Blogs/Gobe",
        title: "Government Official Business Events 2023-2024",
        content: "IITian Pasta Wala, now known as IPW Foods, received multiple invitations to various government business events such as Mithila Haat Event-23, Bihar MSME Connect 2023, Bihar Business Connect 2023, Darbhanga Sathapana Diwas 2023-24, and the Bihar Entrepreneur Association Event 2024.",
    },
    {
        img: blogImage2,
        path: "/Blogs/Sbi",
        title: "Visiting State Bank of India, Darbhanga Branch",
        content: "Under the PMEGP Scheme, we received a loan from SBI Bank, Darbhanga. This blog section, covering about several important events, from the loan sanction to the product launch event held at the head branch of SBI Darbhanga. It was a great experience for us at IITian Pasta Wala.",
    },
    {
        img: blogImage3,
        path: "/Blogs/Mee",
        title: "Mithila Emerging Event 2023",
        content: `The Way, a Media & Advertisement company, organized the "Mithila Emerging Summit 2023" in Darbhanga, where Bihar's Transportation Minister, Smt. Shila Kumari Ji, was present. IITian Pasta Wala Founder & CEO received the Appreciation Award in the Young Inspiring Category.`,
    },
];
export { product, blog };
