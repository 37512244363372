import React from "react";
// import React, { useState } from 'react';
// import Modal from 'react-modal';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Img11 from "../assets/images/1blogpageimg1.jpg";
import Img12 from "../assets/images/1blogpageimg2.jpg";
import Img13 from "../assets/images/1blogpageimg3.jpg";
import Img14 from "../assets/images/1blogpageimg4.jpg";
import Img15 from "../assets/images/1blogpageimg5.jpg";
import Img16 from "../assets/images/1blogpageimg6.jpg";
import Img17 from "../assets/images/1blogpageimg7.jpg";
import Img18 from "../assets/images/1blogpageimg8.jpg";
import Img19 from "../assets/images/1blogpageimg9.jpg";
import Img1_20 from "../assets/images/1blogpageimg10.jpg";
import Img1_21 from "../assets/images/1blogpageimg11.jpg";
import Img1_22 from "../assets/images/1blogpageimg12.jpg";

// Modal.setAppElement('#root');

const Gobe = () => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [modalContent, setModalContent] = useState({});

    // const openModal = (content) => {
    //     setModalContent(content);
    //     setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };

    // const schedule = [
    //     { time: '09:00 AM', activity: 'Opening Ceremony', speaker: 'Alok Kumar' },
    //     { time: '10:00 AM', activity: 'Keynote: The Future of Entrepreneurship', speaker: 'Aashutosh Kumar' },
    // ];

    return (
        <div className=" bg-gray-100 text-gray-900 py-8 px-4">
            <section className="mb-8">
                <h2 className="text-3xl font-bold mb-4 text-center">Government Official Business Events 2023-2024</h2>
                <section>
                    <h2 className="text-2xl text-center font-bold mb-4">Gallery</h2>
                    <Carousel showArrows={true} autoPlay={true} swipeable={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} showThumbs={false} infiniteLoop={true} interval={3000} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} className="rounded shadow-md">
                        <div>
                            <img src={Img11} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img12} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img13} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img14} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img15} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img16} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img17} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img18} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img19} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img1_20} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img1_21} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                        <div>
                            <img src={Img1_22} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Mee Img" />
                        </div>
                    </Carousel>
                </section>
                <p className="leading-relaxed text-lg text-center">Picture 1 & 2: IITian Pasta Wala, मिमिला हाट के उदघाटन िें। With the Principal Secretary Shri Sandeep Poundrik sir, (right) & director sir, (left) of the Industrial department, Bihar.</p>
                <p className="leading-relaxed text-lg text-center">Picture 3, 4 & 5 : Apna Bihar ab dheere badal raha hain, Bihar Connect‘2023. There was a one-day workshop, exhibition & training-based event in Gyan Bhawan Patna by the Industrial Departments of Bihar on 1st April 2023.</p>
                <p className="leading-relaxed text-lg text-center">Picture 6, 7 & 8: Last year, Bihar secured the second prize in the 'National MSME Awards, 2022,' acknowledging its outstanding contribution to the development and promotion of the Micro, Small, and Medium Enterprises (MSME) sector. Good Things About This Event: Investment Commitments / MOUs of more than 50,000 Crores signed during Bihar Business Connect 23: The Global Investors' Summit in Patna on 13-14th Dec 23.</p>
                <p className="leading-relaxed text-lg text-center">Picture 9, 10 & 11 : दरभंगा मिला स्िापना मदवस 150वी वर्षके अवसर पर उद्योग मवभाग पर कु छ important products का stalls लगाया। Special Thankyou DIC Darbhanga</p>
                <p className="leading-relaxed text-lg text-center">Picture 12 : We received the invitation to join the 11th Bihar Entrepreneur Summit 2024 event and also had the opportunity to take a photo with the Chairman of the Bihar Entrepreneur Association (in the center), alongside my senior Anand Kesari on the left.</p>
            </section>

            {/* <section className="mb-8">
                <h2 className="text-2xl text-center font-bold mb-4">Event Highlights</h2>
                <div className="grid md:grid-cols-3 gap-4">
                    <div className="bg-white p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-200 transform hover:-translate-y-1 cursor-pointer" onClick={() => openModal({ title: 'Keynote Speakers', description: 'Learn from the industry leaders who have transformed their vision into reality.', image: 'https://via.placeholder.com/300x200' })}>
                        <img src={Blog1} alt="Keynote Speakers" className="w-full h-auto rounded mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Keynote Speakers</h3>
                        <p>Learn from the industry leaders who have transformed their vision into reality.</p>
                    </div>
                    <div className="bg-white p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-200 transform hover:-translate-y-1 cursor-pointer" onClick={() => openModal({ title: 'Interactive Workshops', description: 'Participate in hands-on sessions to gain practical skills and knowledge.', image: 'https://via.placeholder.com/300x200' })}>
                    <img src={Blog1} alt="Keynote Speakers" className="w-full h-auto rounded mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Interactive Workshops</h3>
                        <p>Participate in hands-on sessions to gain practical skills and knowledge.</p>
                    </div>
                    <div className="bg-white p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-200 transform hover:-translate-y-1 cursor-pointer" onClick={() => openModal({ title: 'Networking Events', description: 'Connect with like-minded professionals and expand your network.', image: 'https://via.placeholder.com/300x200' })}>
                    <img src={Blog1} alt="Keynote Speakers" className="w-full h-auto rounded mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Networking Events</h3>
                        <p>Connect with like-minded professionals and expand your network.</p>
                    </div>
                </div>
            </section> */}

            {/* <section className="mb-8">
                <h2 className="text-2xl text-center font-bold mb-4">Event Schedule</h2>
                <div className="bg-white p-4 rounded shadow-md">
                    {schedule.map((item, index) => (
                        <details key={index} className="border-b mb-2 cursor-pointer">
                            <summary className="py-2 px-4 focus:outline-none hover:bg-gray-200">
                                <div className="flex justify-between">
                                    <span>{item.time} - {item.activity}</span>
                                    <span>{item.speaker}</span>
                                </div>
                            </summary>
                            <div className="px-4 py-2">
                                <p>Details about {item.activity} with {item.speaker}</p>
                            </div>
                        </details>
                    ))}
                </div>
            </section> */}

            {/* <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="max-w-lg mx-auto my-20 p-8 bg-white rounded shadow-lg outline-none">
                <button onClick={closeModal} className="text-red-500 font-bold float-right">X</button>
                <h2 className="text-2xl font-bold mb-4">{modalContent.title}</h2>
                <img src={modalContent.image} alt={modalContent.title} className="w-full h-auto rounded mb-4" />
                <p>{modalContent.description}</p>
            </Modal> */}
        </div>
    );
};

export default Gobe;
