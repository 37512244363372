import React from "react";
import { blog } from "../Data";
import { Link } from "react-router-dom";
const Blog = () => {
    return (
        <>
            <section class="blogs" id="blogs">
                <h1 class="heading">
                    our <span>blogs</span>
                </h1>

                <div class="box-container">
                    {blog.map((item, index) => (
                        <div className="box" key={index}>
                            <Link to={item.path} key={index}>
                                <div className="image">
                                    <img src={item.img} alt="" />
                                </div>
                                <div className="content">
                                    <a href="#" className="title">
                                        {item.title}
                                    </a>
                                    <span>by admin / 21st may, 2024</span>
                                    <p>{item.content}</p>
                                    <button class="btn">
                                        read more
                                    </button>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default Blog;
