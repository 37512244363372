import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
    const footerRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const goTo = (time, id) => {
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }
        }, time);
    };

    const scrollUp = (id, e) => {
        e.preventDefault();

        if (location.pathname !== "/") {
            navigate("/");
            goTo(300, id);
        } else {
            goTo(100, id);
        }
    };

    const footerHandler = (id, e) => {
        e.preventDefault();
        footerRef.current.classList.toggle("active");
        scrollUp(id, e);
    };


    return (
        <>
            <section class="footer">
                <div class="share">
                    <a href="mailto:contact@iitianpastawala.com" class="fas fa-envelope"></a>
                    <a href="https://www.facebook.com/iitianpastawala" target="_blank" rel="noopener noreferrer" class="fab fa-facebook-f"></a>
                    <a href="https://x.com/iitianpastawala" target="_blank" rel="noopener noreferrer" class="fab fa-twitter"></a>
                    <a href="https://www.instagram.com/iitianpastawala/" target="_blank" rel="noopener noreferrer" class="fab fa-instagram"></a>
                    <a href="https://www.linkedin.com/company/iitianpastawala" target="_blank" rel="noopener noreferrer" class="fab fa-linkedin"></a>
                </div>
                <div class="links" ref={footerRef}>
                    <a href="#home" onClick={(e) => footerHandler("home", e)}>
                        home
                    </a>
                    <a href="#products" onClick={(e) => footerHandler("products", e)}>
                        products
                    </a>
                    <a href="#blogs" onClick={(e) => footerHandler("blogs", e)}>
                        blogs
                    </a>
                    <a href="#about" onClick={(e) => footerHandler("about", e)}>
                        about
                    </a>
                    <a href="#contact" onClick={(e) => footerHandler("contact", e)}>
                        contact
                    </a>
                </div>
                <div class="credit">
                    created by{" "}
                    <span>
                        <a href="https://www.mithilastack.com/" target="_blank" rel="noopener noreferrer">
                            Mithila Stack
                        </a>
                    </span>{" "}
                    | all rights reserved
                </div>
            </section>
        </>
    );
};

export default Footer;
