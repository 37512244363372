import React from "react";
import CountUp from "react-countup";

const Hero = () => {
    return (
        <>
            <section className="hero" id="home">
                <div className="content">
                    <h3>
                        <span>Iitian </span>Pastawala
                    </h3>
                    <p>
                        We don’t use Maida, Palm Oil & Sugar. <br />
                        Yes, We are offering “Only” Pure Healthy Products. <br />
                        This is the mission of “IITian Pasta Wala” foods as IPW Foods.
                    </p>
                    <h2 className="md:text-start text-center pt-5 font-bold text-4xl text-amber-300">With Your Love & Support</h2>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                        <div className="flex-1 p-2 pt-7 md:p-4 md:pt-12">
                            <h4 className="font-bold text-xl text-gray-300">We started</h4>
                            <p style={{ fontWeight: 300 }}>Oct 2022</p>
                        </div>
                        <div className="flex-1 p-2 pt-7 md:p-4 md:pt-12">
                            <h4 className="font-bold text-xl text-gray-300">Total Production</h4>
                            <p style={{ fontWeight: 300 }}>
                                <CountUp start={0} end={60000} duration={2.75} separator="," prefix="+" />
                                <br />
                                kg by May 2024
                            </p>
                        </div>
                        <div className="flex-1 p-2 pt-7 md:p-4 md:pt-12">
                            <h4 className="font-bold text-xl text-gray-300">
                                Currently
                                <br />
                                Serving
                            </h4>
                            <p style={{ fontWeight: 300 }}>Darbhanga & Madhubani</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
