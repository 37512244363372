import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PastaImg from ".././assets/images/products-12.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";

function CornPasta() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const formik = useFormik({
        initialValues: {
            name: "",
            mobile: "",
            address: "",
            product: "",
            quantity: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            mobile: Yup.string()
                .matches(/^[0-9]{10}$/, "Mobile must be 10 digits")
                .required("Mobile is required"),
            address: Yup.string().required("Address is required"),
            product: Yup.string().required("Product is required"),
            quantity: Yup.number().required("Quantity is required"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            const formData = new FormData();
            Object.keys(values).forEach((key) => formData.append(key, values[key]));
            // Append timestamp
            formData.append("timestamp", new Date().toLocaleString());
            try {
                await fetch("https://script.google.com/macros/s/AKfycbxQyV7HKsPEFrDesd5xre-I9XHwcsM3i_Ja2f0CDxcweVKQ51lmoKw7nVgXNqJ57iD-/exec", {
                    method: "POST",
                    body: formData,
                });
                toast.success("Order Booked successfully!");
                formik.resetForm();
            } catch (error) {
                toast.error("Error Booking Order!");
            }

            setTimeout(() => {
                setLoading(false);
                setMessage("Order booked successfully!");
            }, 2000);
        },
    });

    return (
        <div className="flex flex-col sm:flex-row p-7 bg-white">
            <ToastContainer />
            <div className="w-full sm:w-1/2 pt-32">
                <img src={PastaImg} alt="Product" className="w-3/4 h-3/4 object-contain mx-auto" />
            </div>
            <div className="w-full sm:w-1/2 sm:mr-24 pt-32">
                <div className="mb-8 px-4 sm:px-0">
                    <h1 className="text-4xl font-light mb-4">Product Description</h1>
                    <h2 className="text-3xl font-bold mb-4"> Makai(Corn) Pasta</h2>
                    <p>Weight: 500 gm</p>
                    <p>MRP (Including Tax): 180/-</p>
                    <p>Ingredients: Only Makai (Corn) & Semolina (Suji)</p>
                   
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <label className="block mb-2">Name</label>
                            <input type="text" name="name" value={formik.values.name} onChange={formik.handleChange} className="mb-4 p-2 border border-black rounded w-full hover:border-blue-500 focus:border-blue-500 focus:outline-none" />
                            {formik.errors.name && formik.touched.name && <span className="error text-red-500">{formik.errors.name}</span>}
                        </div>
                        <div>
                            <label className="block mb-2">Mobile</label>
                            <input type="mobile" name="mobile" placeholder="mobile" value={formik.values.mobile} onChange={formik.handleChange} className="mb-4 p-2 border border-black  rounded w-full hover:border-blue-500 focus:border-blue-500 focus:outline-none" />
                            {formik.errors.mobile && formik.touched.mobile && <span className="error text-red-500">{formik.errors.mobile}</span>}
                        </div>
                        <div>
                            <div>
                                <label className="block mb-2">Product</label>
                                <select name="product" value={formik.values.product} onChange={formik.handleChange} className="mb-4 p-2 border border-black rounded w-full hover:border-blue-500 focus:border-blue-500 focus:outline-none">
                                    <option value="">Select a product</option>
                                    <option value="Makai(Corn) Macaroni">Makai(Corn) Macaroni</option>
                                    <option value="Makai(Corn) Penny">Makai(Corn) Penny</option>
                                    <option value="Makai(Corn) Vermicelli">Makai(Corn) Vermicelli</option>
                                    <option value="Makai(Corn) Pasta">Makai(Corn) Pasta</option>
                                </select>
                                {formik.errors.product && formik.touched.product && <span className="error text-red-500">{formik.errors.product}</span>}
                            </div>
                            <label className="block mb-2">Address</label>
                            <input type="text" name="address" placeholder="Address" value={formik.values.address} onChange={formik.handleChange} className="mb-4 p-2 border border-black rounded w-full hover:border-blue-500 focus:border-blue-500 focus:outline-none" />
                            {formik.errors.address && formik.touched.address && <span className="error text-red-500">{formik.errors.address}</span>}
                        </div>
                        <div>
                            <label className="block mb-2">Quantity (500gm)</label>
                            <input type="text" name="quantity" placeholder="Quantity" value={formik.values.quantity} onChange={formik.handleChange} className="mb-4 p-2 border border-black rounded w-full hover:border-blue-500 focus:border-blue-500 focus:outline-none" />
                            {formik.errors.quantity && formik.touched.quantity && <span className="error text-red-500">{formik.errors.quantity}</span>}
                        </div>
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded transition duration-200" disabled={loading}>
                            {loading ? "Booking..." : "Book Order Now"}
                        </button>
                    </form>
                    {message && <p className="mt-4 text-green-500">{message}</p>}
                </div>
            </div>
        </div>
    );
}

export default CornPasta;
