import React from "react";
// import React, { useState } from 'react';
// import Modal from 'react-modal';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Img21 from "../assets/images/2blogpageimg1.jpg";
import Img22 from "../assets/images/2blogpageimg2.jpg";
import Img23 from "../assets/images/2blogpageimg3.jpg";
import Img24 from "../assets/images/2blogpageimg4.jpg";

// Modal.setAppElement('#root');

const Sbi = () => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [modalContent, setModalContent] = useState({});

    // const openModal = (content) => {
    //     setModalContent(content);
    //     setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };

    // const schedule = [
    //     { time: '09:00 AM', activity: 'Opening Ceremony', speaker: 'Alok Kumar' },
    //     { time: '10:00 AM', activity: 'Keynote: The Future of Entrepreneurship', speaker: 'Aashutosh Kumar' },
    // ];

    return (
        <div className=" bg-gray-100 text-gray-900 py-8 px-4">
            <section className="mb-8">
                <h2 className="text-3xl font-bold mb-4 text-center">SBI Events Diary: PMEGP Loan Journey</h2>
                <section>
                    <h2 className="text-2xl text-center font-bold mb-4">Gallery</h2>
                    <Carousel showArrows={true} autoPlay={true} swipeable={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} showThumbs={false} infiniteLoop={true} interval={3000} className="rounded shadow-md">
    <div>
        <img src={Img21} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Sbi Img" />
    </div>
    <div>
        <img src={Img22} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Sbi Img" />
    </div>
    <div>
        <img src={Img23} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Sbi Img" />
    </div>
    <div>
        <img src={Img24} className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] object-fill md:object-cover" alt="Sbi Img" />
    </div>
</Carousel>
                </section>
                <p className="leading-relaxed text-lg text-center">Picture 1, 2 & 3 : We got the PMGP Loan Sanction letter from SBI Darbhanga, Head Branch. It was quite great experience for us and within 15 days, our loan was approved. Thankyou SBI Darbhanga for your support, love & gifts.</p>
                <p className="leading-relaxed text-lg text-center">Picture 4: After setting up Pasta Manufacturing Business, We met the bank officials once again with products Macaroni & Pasta and they also purchased some products for their own family. Thanks for Reading.</p>
            </section>

            {/* <section className="mb-8">
                <h2 className="text-2xl text-center font-bold mb-4">Event Highlights</h2>
                <div className="grid md:grid-cols-3 gap-4">
                    <div className="bg-white p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-200 transform hover:-translate-y-1 cursor-pointer" onClick={() => openModal({ title: 'Keynote Speakers', description: 'Learn from the industry leaders who have transformed their vision into reality.', image: 'https://via.placeholder.com/300x200' })}>
                        <img src={Blog1} alt="Keynote Speakers" className="w-full h-auto rounded mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Keynote Speakers</h3>
                        <p>Learn from the industry leaders who have transformed their vision into reality.</p>
                    </div>
                    <div className="bg-white p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-200 transform hover:-translate-y-1 cursor-pointer" onClick={() => openModal({ title: 'Interactive Workshops', description: 'Participate in hands-on sessions to gain practical skills and knowledge.', image: 'https://via.placeholder.com/300x200' })}>
                    <img src={Blog1} alt="Keynote Speakers" className="w-full h-auto rounded mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Interactive Workshops</h3>
                        <p>Participate in hands-on sessions to gain practical skills and knowledge.</p>
                    </div>
                    <div className="bg-white p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-200 transform hover:-translate-y-1 cursor-pointer" onClick={() => openModal({ title: 'Networking Events', description: 'Connect with like-minded professionals and expand your network.', image: 'https://via.placeholder.com/300x200' })}>
                    <img src={Blog1} alt="Keynote Speakers" className="w-full h-auto rounded mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Networking Events</h3>
                        <p>Connect with like-minded professionals and expand your network.</p>
                    </div>
                </div>
            </section> */}

            {/* <section className="mb-8">
                <h2 className="text-2xl text-center font-bold mb-4">Event Schedule</h2>
                <div className="bg-white p-4 rounded shadow-md">
                    {schedule.map((item, index) => (
                        <details key={index} className="border-b mb-2 cursor-pointer">
                            <summary className="py-2 px-4 focus:outline-none hover:bg-gray-200">
                                <div className="flex justify-between">
                                    <span>{item.time} - {item.activity}</span>
                                    <span>{item.speaker}</span>
                                </div>
                            </summary>
                            <div className="px-4 py-2">
                                <p>Details about {item.activity} with {item.speaker}</p>
                            </div>
                        </details>
                    ))}
                </div>
            </section> */}

            {/* <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="max-w-lg mx-auto my-20 p-8 bg-white rounded shadow-lg outline-none">
                <button onClick={closeModal} className="text-red-500 font-bold float-right">X</button>
                <h2 className="text-2xl font-bold mb-4">{modalContent.title}</h2>
                <img src={modalContent.image} alt={modalContent.title} className="w-full h-auto rounded mb-4" />
                <p>{modalContent.description}</p>
            </Modal> */}
        </div>
    );
};

export default Sbi;
