import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import styled from "styled-components";


const Logo = styled.a`
display: flex;
align-items: center;
color: white;
cursor: pointer;
margin: 0 0;
font-size: 1.6rem;
color: #fff;
  img {
    max-width: 55px;
    margin-right: 1rem;
  }
`;

const Heading = styled.h1`
  text-align: center;
  color: #fff;
  text-transform: uppercase;

  span {
    color: #ffc107;
    text-transform: uppercase;
  }
`;


const Navbar = () => {
  const navbarRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const goTo = (time, id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, time);
  };

  const scrollUp = (id, e) => {
    e.preventDefault();

    if (location.pathname !== "/") {
      navigate("/");
      goTo(300, id);
    } else {
      goTo(100, id);
    }
  };

  const navbarHandler = (id, e) => {
    e.preventDefault();
    navbarRef.current.classList.toggle("active");
    scrollUp(id, e);
  };

  return (
    <>
      <header className="header">
      <Logo href={location.pathname === "/" ? "/" : "/"}>
          <img src={logo} alt="MithilaStack Logo" />
          <Heading><span>IITian</span> Pastawala</Heading>
        </Logo>
        <nav className="navbar" ref={navbarRef}>
          <a href="#home" onClick={(e) => navbarHandler("home", e)}>Home</a>
          <a href="#products" onClick={(e) => navbarHandler("products", e)}>Products</a>
          <a href="#blogs" onClick={(e) => navbarHandler("blogs", e)}>Blogs</a>
          <a href="#about" onClick={(e) => navbarHandler("about", e)}>About</a>
          <a href="#contact" onClick={(e) => navbarHandler("contact", e)}>Contact</a>
        </nav>
        <div className="icons">
          <div
            className="fas fa-bars"
            id="menu-btn"
            onClick={() => navbarRef.current.classList.toggle("active")}
          ></div>
        </div>
      </header>
    </>
  );
};

export default Navbar;