import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import MacaroniPasta from "./pages/macaroniPasta";
import PennyPista from "./pages/pennyPasta";
import VermicelliPasta from "./pages/vermicelliPasta";
import PlainPasta from "./pages/plainPasta";
import MakhanaMacroni from "./pages/MakhanaMacroni";
import MakhanaPenny from "./pages/MakhanaPenny";
import MakhanaVermicelli from "./pages/MakhanaVermicelli";
import MakhanaPasta from "./pages/MakhanaPasta";
import CornMacroni from "./pages/CornMacroni";
import CornPenny from "./pages/CornPenny";
import CornVermicelli from "./pages/CornVermicelli";
import CornPasta from "./pages/CornPasta";
import Gobe from "./pages/Gobe";
import Sbi from "./pages/Sbi";
import Mee from "./pages/Mee";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    return (
        <>
            <Router>
                <ScrollToTop />
                <Navbar />
                <div className="mt-32 lg:mt-16">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Products/MakhanaMacroni" element={<MakhanaMacroni />} />
                        <Route path="/Products/MakhanaPenny" element={<MakhanaPenny />} />
                        <Route path="/Products/MakhanaVermicelli" element={<MakhanaVermicelli />} />
                        <Route path="/Products/MakhanaPasta" element={<MakhanaPasta />} />
                        <Route path="/Products/CornMacroni" element={<CornMacroni />} />
                        <Route path="/Products/CornPenny" element={<CornPenny />} />
                        <Route path="/Products/CornVermicelli" element={<CornVermicelli />} />
                        <Route path="/Products/CornPasta" element={<CornPasta />} />
                        <Route path="/Products/MacaroniPasta" element={<MacaroniPasta />} />
                        <Route path="/Products/PennyPasta" element={<PennyPista />} />
                        <Route path="/Products/VermicelliPasta" element={<VermicelliPasta />} />
                        <Route path="/Products/PlainPasta" element={<PlainPasta />} />
                        <Route path="/Blogs/Gobe" element={<Gobe />} />
                        <Route path="/Blogs/Sbi" element={<Sbi />} />
                        <Route path="/Blogs/Mee" element={<Mee />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </>
    );
}
export default App;
