import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const errors = {};

        if (!name.trim()) {
            errors.name = "Name is required";
        }
        if (!email.trim()) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email is invalid";
        }
        if (!number.trim()) {
            errors.number = "Number is required";
        } else if (isNaN(number)) {
            errors.number = "Number must be numeric";
        } else if (number.length !== 10) {
            errors.number = "Number must be 10 digits";
        }
        if (!address.trim()) {
            errors.address = "Address is required";
        }

        if (Object.keys(errors).length === 0) {
            try {
                await fetch("https://script.google.com/macros/s/AKfycbwUck-6_8_fiU5rCmmnqeXaMmylJUY-12LR6gneud5fipOlQoNsNPz0fVkNzRpZhfJC/exec", {
                    method: "POST",
                    body: formData,
                });
                toast.success("Form submitted successfully!");
                // Reset form fields after successful submission
                setName("");
                setEmail("");
                setNumber("");
                setAddress("");
                // Clear error state after successful submission
            setErrors({});
            } catch (error) {
                toast.error("Error submitting form");
            }
        } else {
            setErrors(errors);
        }
    };
    return (
        <>
            <section class="contact" id="contact">
                <h1 class="heading">
                    <span>contact</span> us
                </h1>
                <div class="row">
                    <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.586615618574!2d85.8668057!3d26.1775892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39edb703552867c7%3A0x53c2bb996fb90315!2sMahalata%20Ji%20Enterprises%20as%20IITian%20Pasta%20Wala!5e0!3m2!1sen!2sin!4v1715350192821!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>

                    <form onSubmit={handleSubmit}>
                        <h3>Contact You Shortly</h3>
                        <p style={{ color: 'white', fontSize: '15px' }}>(Business Inquiry: for Stockists, Distributors & Retailers)</p>
                        <div className="inputBox">
                            <span className="fas fa-user"></span>
                            <input type="text" name="your-name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            {errors.name && <span className="error">{errors.name}</span>}
                        </div>
                        <div className="inputBox">
                            <span className="fas fa-envelope"></span>
                            <input type="email" name="your-email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="inputBox">
                            <span className="fas fa-phone"></span>
                            <input type="number" name="your-number" placeholder="Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                            {errors.number && <span className="error">{errors.number}</span>}
                        </div>
                        <div className="inputBox">
                            <span className="fas fa-map-marker-alt"></span>
                            <input type="text" name="your-address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                            {errors.address && <span className="error">{errors.address}</span>}
                        </div>
                        <input type="submit" value="Book now" className="btn" />
                    </form>
                </div>
            </section>
            <ToastContainer />
        </>
    );
};

export default Contact;
